// 引入 axios
import axios from "axios";



let base = process.env.VUE_APP_API_BASE_URL+"/portal-server";
//let base = 'http://localhost:8888/portal-server';

//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data: params,
    })
}
